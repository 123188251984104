<template>
  <sign-up-carousel-wrapper>
    <template #img>
      <img
        height="392"
        src="@/assets/img/signup-carousel-second@2x.png"
        alt=""
      />
    </template>
    <template #title>{{ $t("SignUp_Carousel_Title2") }}</template>
    <template #caption>
      {{ $t("SignUp_Carousel_Caption2") }}
    </template>
  </sign-up-carousel-wrapper>
</template>

<script>
import SignUpCarouselWrapper from "./SignUpCarouselWrapper.vue";

export default {
  components: { SignUpCarouselWrapper },
};
</script>

<style></style>
